import React, { useEffect } from "react";
import { motion } from "framer-motion";
import "./HamburgerMenuModal.css";
import whiteLogo from "../../images/whiteLogo.svg";
import usFlag from "../../images/usFlag.svg";
import mxFlag from "../../images/mxFlag.svg";

import { scrollToSection } from "../../helpers";
import { useTranslation } from "react-i18next";

const modalVariants = {
  hidden: { opacity: 0, x: "100%" }, 
  visible: { opacity: 1, x: "0%" },
  exit: { opacity: 0, x: "100%" }, 
};

export default function HamburgerMenuModal ({ isOpen, onClose, handleLanguageChange }) {
  const { t, i18n } = useTranslation();

  const handleNavClick = (e, section) => {
    e.preventDefault();
    scrollToSection(section);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      // Set focus to the first interactive element
      document.querySelector(".close-btn").focus();
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <motion.nav
      className="modal-overlay"
      onClick={onClose}
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={modalVariants}
      transition={{ duration: 0.3 }}
    >
      <div
        className="menu-content"
        onClick={(e) => e.stopPropagation()}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modalTitle"
      >
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <div className="mobileNavbar">
          <img className="nav-logo" src={whiteLogo} alt="logo" />
          <ul className="nav-menu-mobile">
            <li className="nav-item">
              <button
                className="nav-link"
                onClick={(e) => handleNavClick(e, "skills")}
              >
                {t("skills")}
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                onClick={(e) => handleNavClick(e, "portfolio")}
              >
                {t("portfolio")}
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                onClick={(e) => handleNavClick(e, "education")}
              >
                {t("education")}
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link special"
                onClick={(e) => handleNavClick(e, "contact")}
              >
                {t("letsTalk")}
              </button>
            </li>
            <button
              className="languageBtnMobile"
              type="button"
              onClick={handleLanguageChange}
            >
              <img
                src={i18n.language === "en" ? usFlag : mxFlag}
                alt={
                  i18n.language === "en" ? "United States Flag" : "Mexican Flag"
                }
              />
            </button>
          </ul>
        </div>
      </div>
    </motion.nav>
  );
};
