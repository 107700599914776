// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            description: "This is your app",
            skills: "Skills",
            portfolio: "Portfolio",
            education: "Education",
            hello: "Hello",
            iAm: "I'm",
            fullStackDeveloper: "Developing digital solutions with the heart and experience of a full-stack developer.",
            letsTalk: "Let’s talk!",
            languagesAndTools: "Languages and Tools",
            languages: "Languages",
            devAndCloudTools: "Development and cloud tools",
            librariesAndFrameworks: "Libraries and Frameworks",
            workExamples: "Work Samples",
            featuredProjects: "Featured Projects",
            viewProject: "View Project",
            certifications: "Certifications",
            certificates: "Certificates",
            computationalTechnologiesEngineering: "Engineering in Computational Technologies",
            tecMonterrey: "Tecnológico de Monterrey",
            learnPython: "Learn to Program with Python",
            gitWorkflows: "GIT Workflows",
            arduinoIntro: "Introduction to Arduino",
            fullWebDevCourse: "Complete Web Development Course with HTML, CSS, PHP, MySQL, Node.js (72 hours)",
            letsCreate: "Let’s create",
            something: "something",
            together: "together!",
            name: "Name",
            email: "Email Address",
            phone: "Phone Number",
            message: "Message",
            send: "Send",
            allRightsReserved: "All rights reserved",
            messageSent: "Message sent successfully",
            mailSubject: "Contact from your portfolio",
            mailBody: "Hello, Juan. I would like to talk to you about...",
        }
    },
    es: {
        translation: {
            description: "Esta es tu aplicación",
            skills: "Habilidades",
            portfolio: "Portafolio",
            education: "Educación",
            hello: "Hola",
            iAm: "Soy",
            fullStackDeveloper: "Desarrollando soluciones digitales con el corazón y la experiencia de un desarrollador full stack.",
            letsTalk: "¡Hablemos!",
            languagesAndTools: "Lenguajes y Herramientas",
            languages: "Lenguajes",
            devAndCloudTools: "Herramientas de desarrollo y nube",
            librariesAndFrameworks: "Librerías y Frameworks",
            workExamples: "Ejemplos de trabajo",
            featuredProjects: "Proyectos Destacados",
            viewProject: "Ver Proyecto",
            certifications: "Certificaciones",
            certificates: "Certificados",
            computationalTechnologiesEngineering: "Ingeniería en Tecnologías Computacionales",
            tecMonterrey: "Tecnológico de Monterrey",
            learnPython: "Aprende a Programar con Python",
            gitWorkflows: "Flujos de trabajo GIT",
            arduinoIntro: "Introducción a Arduino",
            fullWebDevCourse: "Curso completo de desarrollo web con HTML, CSS, PHP, MySQL, Node.js (72 horas)",
            letsCreate: "¡Creémos",
            something: "algo",
            together: "juntos!",
            name: "Nombre",
            email: "Correo Electrónico",
            phone: "Número Telefónico",
            message: "Mensaje",
            send: "Enviar",
            allRightsReserved: "Todos los derechos reservados",
            messageSent: "Mensaje enviado exitosamente",
            mailSubject: "Contacto desde tu portafolio",
            mailBody: "Hola, Juan. Me gustaría hablar contigo acerca de...",
        }
    }
};


i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en", // default language
        fallbackLng: "en", // language to use if the current one is not available
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;