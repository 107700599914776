import React from "react";
import "./Education.css";
import ScrollAnimation from "../../components/ScrollAnimation/ScrollAnimation";

import EducationLogo from "../../images/icons/education.svg";
import GraduationLogo from "../../images/icons/graduation.svg";
import Polygon from "../../images/polygon.svg";
import { useTranslation } from "react-i18next";

export default function Education() {
  const { t } = useTranslation();

  const certificates = [
    {
      id: 0,
      icon: EducationLogo,
      alt: "Python",
      label: t("learnPython"),
    },
    {
      id: 1,
      icon: EducationLogo,
      alt: "GIT",
      label: t("gitWorkflows"),
    },
    {
      id: 2,
      icon: EducationLogo,
      alt: "Arduino",
      label: t("arduinoIntro"),
    },
    {
      id: 3,
      icon: EducationLogo,
      alt: "HTML",
      label: t("fullWebDevCourse"),
    },
  ];

  return (
    <ScrollAnimation id="education" className="education maxWidth">
      <div className="titleContainer">
        <h3 className="subtitle">{t("education")}</h3>
        <h2 className="title">{t("certifications")}</h2>
      </div>

      <div className="educationContainer">
        <h4 className="boxTitle">{t("certificates")}</h4>
        <div className="boxContainer">
          {certificates.map((certificate) => (
            <div className="certificateBox" key={certificate.id}>
              <div className="polygonContainer">
                <img
                  src={Polygon}
                  alt="Polygon"
                  className="polygon"
                  width="50"
                  height="50"
                  loading="lazy"
                />
                <img
                  className="icon"
                  src={certificate.icon}
                  alt={certificate.alt}
                  width="40"
                  height="40"
                  loading="lazy"
                />
              </div>
              <p className="text">{certificate.label}</p>
            </div>
          ))}
        </div>

        <h4 className="boxTitle">{t("education")}</h4>
        <div className="educationBox">
          <div className="polygonContainer">
            <img
              src={Polygon}
              alt="Polygon"
              className="polygon"
              width="50"
              height="50"
              loading="lazy"
            />
            <img
              className="icon"
              src={GraduationLogo}
              alt="Graduation icon"
              width="40"
              height="40"
              loading="lazy"
            />
          </div>
          <p className="career">{t("computationalTechnologiesEngineering")}</p>
          <p className="school">{t("tecMonterrey")}</p>
          <p className="year">2020-2024</p>
        </div>
      </div>
    </ScrollAnimation>
  );
};
