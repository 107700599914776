import React from "react";
import "./Skills.css";

import AWS from "../../images/LanguagesLogos/aws.svg";
import Git from "../../images/LanguagesLogos/git.svg";
import JS from "../../images/LanguagesLogos/javascript.svg";
import MongoDB from "../../images/LanguagesLogos/mongodb.webp";
import MySQL from "../../images/LanguagesLogos/mysql.svg";
import Node from "../../images/LanguagesLogos/nodejs.svg";
import PHP from "../../images/LanguagesLogos/php.webp";
import Python from "../../images/LanguagesLogos/python.svg";
import ReactLogo from "../../images/LanguagesLogos/react.svg";
import Symfony from "../../images/LanguagesLogos/symfony.webp";
import TypeScript from "../../images/LanguagesLogos/typescript.webp";
import NextJs from "../../images/LanguagesLogos/next.svg";
import CPP from "../../images/LanguagesLogos/cpp.svg";
import PostgreSQL from "../../images/LanguagesLogos/postgresql.svg";

import Polygon from "../../images/polygon.svg";
import { useTranslation } from "react-i18next";
import { scrollToSection } from "../../helpers";
import ScrollAnimation from "../../components/ScrollAnimation/ScrollAnimation";

export default function Skills() {
  const { t } = useTranslation();

  const handleNavClick = (section) => () => scrollToSection(section);

  const languages = [
    { name: "Python 3", img: Python },
    { name: "C++", img: CPP },
    { name: "JavaScript", img: JS },
    { name: "PHP 8.0", img: PHP },
    { name: "MySQL", img: MySQL },
    { name: "MongoDB", img: MongoDB },
    { name: "PostgreSQL", img: PostgreSQL },
  ];

  const cloud = [
    { name: "GIT", img: Git },
    { name: "AWS (S3, EC2)", img: AWS },
  ];

  const frameworks = [
    { name: "React.js", img: ReactLogo },
    { name: "Next.js", img: NextJs },
    { name: "React Native", img: ReactLogo },
    { name: "PHP Symfony", img: Symfony },
    { name: "Node.js", img: Node },
    { name: "TypeScript", img: TypeScript },
  ];

  return (
    <ScrollAnimation
      id="skills"
      className="skills maxWidth"
      aria-labelledby="skills-title"
    >
      <div className="titleContainer">
        <h3 className="subtitle" id="skills-title">
          {t("skills")}
        </h3>
        <h2 className="title">{t("languagesAndTools")}</h2>
      </div>
      <div className="skillsContainer">
        <div className="skillBox">
          <div className="grid4">
            {languages.map((language, index) => (
              <div className="languageItem" key={index}>
                <img
                  src={Polygon}
                  alt="Decorative polygon"
                  className="polygon"
                />
                <img
                  className="languageImg"
                  src={language.img}
                  alt={language.name}
                  loading="lazy"
                />
                <p className="label">{language.name}</p>
              </div>
            ))}
          </div>
          <h3 className="subtitle">{t("languages")}</h3>
        </div>
        <div className="skillBox">
          <div className="grid2">
            {cloud.map((cloudTool, index) => (
              <div className="languageItem" key={index}>
                <img
                  src={Polygon}
                  alt="Decorative polygon"
                  className="polygonBig"
                />
                <img
                  className="languageImgBig"
                  src={cloudTool.img}
                  alt={cloudTool.name}
                  loading="lazy"
                />
                <p className="label">{cloudTool.name}</p>
              </div>
            ))}
          </div>
          <h3 className="subtitle">{t("devAndCloudTools")}</h3>
        </div>
        <div className="skillBox">
          <div className="grid3">
            {frameworks.map((framework, index) => (
              <div className="languageItem" key={index}>
                <img
                  src={Polygon}
                  alt="Decorative polygon"
                  className="polygon"
                />
                <img
                  className="languageImg"
                  src={framework.img}
                  alt={framework.name}
                  loading="lazy"
                />
                <p className="label">{framework.name}</p>
              </div>
            ))}
          </div>
          <h3 className="subtitle">{t("librariesAndFrameworks")}</h3>
        </div>
      </div>
      <button
        className="btn"
        onClick={handleNavClick("contact")}
        aria-label={t("letsTalk")}
      >
        {t("letsTalk")}
      </button>
    </ScrollAnimation>
  );
}
