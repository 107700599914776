import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {motion} from "framer-motion";
import whiteLogo from "../../images/whiteLogo.svg";
import usFlag from "../../images/usFlag.svg";
import mxFlag from "../../images/mxFlag.svg";
import { scrollToSection } from "../../helpers";
import HamburgerMenuModal from "../../components/HamburgerMenuModal/HamburgerMenuModal";
import HamburgerMenu from "../../images/icons/hamburgerMenu.svg";

import "./Navbar.css";

export default function Navbar() {
  const { t, i18n } = useTranslation();
  const [showNavBar, setShowNavBar] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);

  const handleLanguageChange = useCallback(() => {
    const newLanguage = i18n.language === "en" ? "es" : "en";
    i18n.changeLanguage(newLanguage);
  }, [i18n]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial state

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNavClick = (section) => () => scrollToSection(section);

  return (
    <>
      <HamburgerMenuModal
        isOpen={showNavBar}
        onClose={() => setShowNavBar(false)}
        handleLanguageChange={handleLanguageChange}
      />
      <nav id="navbar" className="navbar maxWidth">
        <a href="/" aria-label="Homepage">
          <img className="nav-logo" src={whiteLogo} alt="Company logo" />
        </a>
        {isMobile ? (
          <motion.button
            className="hamburgerMenu"
            type="button"
            onClick={() => setShowNavBar(true)}
            whileHover={{ scale: 1.2, rotate: 90 }}
            whileTap={{
              scale: 0.8,
              rotate: -90,
              borderRadius: "100%",
            }}
          >
            <img src={HamburgerMenu} alt="Menu" />
          </motion.button>
        ) : (
          <ul className="nav-menu">
            <li className="nav-item">
              <button className="nav-link" onClick={handleNavClick("skills")}>
                {t("skills")}
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                onClick={handleNavClick("portfolio")}
              >
                {t("portfolio")}
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                onClick={handleNavClick("education")}
              >
                {t("education")}
              </button>
            </li>
            <li className="nav-item">
              <button
                className="btn smallBtn"
                onClick={handleNavClick("contact")}
              >
                {t("letsTalk")}
              </button>
            </li>
            <li className="nav-item">
              <button
                className="languageBtn"
                type="button"
                onClick={handleLanguageChange}
              >
                <img
                  src={i18n.language === "en" ? usFlag : mxFlag}
                  alt={
                    i18n.language === "en"
                      ? "United States Flag"
                      : "Mexican Flag"
                  }
                />
              </button>
            </li>
          </ul>
        )}
      </nav>
    </>
  );
}
