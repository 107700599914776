import React from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  
  return (
    <footer id="footer" className="footer maxWidth" aria-label="Footer">
      <p>
        &copy;{currentYear} - Juan Pablo Capobianco - {t("allRightsReserved")}
      </p>
    </footer>
  );
}
