import React from "react";
import WavingHand from "../../components/WavingHand/index.jsx";
import LinkedInPicWebp from "../../images/LinkedInPic.webp";
import "./Header.css";
import { useTranslation } from "react-i18next";
import { scrollToSection } from "../../helpers.js";

export default function Header() {
  const { t } = useTranslation();

  const handleNavClick = (section) => () => scrollToSection(section);

  return (
    <header id="header" className="header maxWidth" role="banner">
      <img
        className="linkedInPic"
        src={LinkedInPicWebp}
        alt="LinkedIn profile of Juan Pablo Capobianco"
        loading="lazy"
      />
      <h1>
        {t("hello")} <WavingHand />, {t("iAm")}{" "}
        <span className="blueText">Juan Pablo Capobianco</span>
      </h1>
      <p>{t("fullStackDeveloper")}</p>
      <button
        className="btn"
        onClick={handleNavClick("contact")}
        aria-label={t("letsTalk")}
      >
        {t("letsTalk")}
      </button>
    </header>
  );
}
