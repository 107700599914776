import { useEffect } from 'react';
import { motion, useScroll, useSpring } from "framer-motion"
import emailjs from "@emailjs/browser";
import './App.css';

import Navbar from './sections/Navbar/Navbar';
import Header from './sections/Header/Header';
import Skills from './sections/Skills/Skills';
import Projects from './sections/Projects/Projects';
import Education from './sections/Education/Education';
import Contact from './sections/Contact/Contact';
import Footer from './sections/Footer/Footer';

export default function App() {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_PUBLICKEY);
  }, []);

  return (
    <main className='mainContainer'>
      <motion.div className="progress-bar" style={{ scaleX }} />
      <div className="top-bg">
        <Navbar />
        <Header />
      </div>
      <div className="mid-bg">
        <Skills />
      </div >
      <Projects />
      <Education />
      <div className="bottom-bg">
        <Contact />
        <Footer />
      </div>
    </main>
  );
};
