import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Carousel from "react-bootstrap/Carousel";
import "./Modal.css"; // Custom styles for modal

const modalVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.8 },
};

export default function Modal({ isOpen, onClose, logo, images }) {
  useEffect(() => {
    const handleBodyStyle = (overflow, scrolling, touchAction) => {
      document.body.style.overflow = overflow;
      document.body.style.webkitOverflowScrolling = scrolling;
      document.body.style.touchAction = touchAction;
    };

    if (isOpen) {
      handleBodyStyle("hidden", "touch", "none");
    } else {
      handleBodyStyle("auto", "auto", "auto");
    }

    return () => handleBodyStyle("auto", "auto", "auto"); // Cleanup on unmount or isOpen change
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className="modal-overlay"
      onClick={onClose}
      aria-modal="true"
      role="dialog"
    >
      <motion.div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        role="document"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={modalVariants}
        transition={{ duration: 0.3 }}
      >
        <button
          className="close-btn"
          onClick={onClose}
          aria-label="Close modal"
        >
          &times;
        </button>
        <img
          className="modalLogo"
          src={logo}
          alt="Project logo"
          width="100"
          height="100"
          loading="lazy"
        />
        <div className="carousel-container">
          <Carousel slide={false} interval={null}>
            {images.map((img, index) => (
              <Carousel.Item key={index}>
                <img
                  className="modalImage"
                  src={img}
                  alt={`Project ${index + 1}`}
                  width="100%"
                  height="auto"
                  loading="lazy"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </motion.div>
    </div>
  );
}
