import React, { useState } from "react";
import "./Projects.css";

import PLRLogo from "../../images/ProjectLogos/PLR.webp";
import GildanLogo from "../../images/ProjectLogos/Gildan.webp";
import FawTrucksLogo from "../../images/ProjectLogos/faw-truck.svg";
import JPWLogo from "../../images/ProjectLogos/JPW.webp";
import XcapeLogo from "../../images/ProjectLogos/xcape.svg";
import UTLLogo from "../../images/ProjectLogos/UTLLogo.svg";
import { useTranslation } from "react-i18next";
import { scrollToSection } from "../../helpers";
import Modal from "../../components/Modal/Modal";
import ScrollAnimation from "../../components/ScrollAnimation/ScrollAnimation";

// Images for Modal
import PLR from "../../images/ProjectImages/PLR.webp";
import PLR2 from "../../images/ProjectImages/PLR2.webp";
import PLR3 from "../../images/ProjectImages/PLR3.webp";

import Gildan from "../../images/ProjectImages/Gildan.webp";
import Gildan2 from "../../images/ProjectImages/Gildan2.webp";
import Gildan3 from "../../images/ProjectImages/Gildan3.webp";

import ELAM from "../../images/ProjectImages/ELAM.webp";
import ELAM2 from "../../images/ProjectImages/ELAM2.webp";
import ELAM3 from "../../images/ProjectImages/ELAM3.webp";
import ELAM4 from "../../images/ProjectImages/ELAM4.webp";
import ELAM5 from "../../images/ProjectImages/ELAM5.webp";

import JPW from "../../images/ProjectImages/JPW.webp";
import JPW2 from "../../images/ProjectImages/JPW2.webp";
import JPW3 from "../../images/ProjectImages/JPW3.webp";

import XCAPE from "../../images/ProjectImages/XCAPE.webp";
import XCAPE2 from "../../images/ProjectImages/XCAPE2.webp";
import XCAPE3 from "../../images/ProjectImages/XCAPE3.webp";

import UTL from "../../images/ProjectImages/UTL.webp";
import UTL2 from "../../images/ProjectImages/UTL2.webp";
import UTL3 from "../../images/ProjectImages/UTL3.webp";
import UTL4 from "../../images/ProjectImages/UTL4.webp";
import UTL5 from "../../images/ProjectImages/UTL5.webp";
import UTL6 from "../../images/ProjectImages/UTL6.webp";
import UTL7 from "../../images/ProjectImages/UTL7.webp";
import UTL8 from "../../images/ProjectImages/UTL8.webp";
import UTL9 from "../../images/ProjectImages/UTL9.webp";

export default function Projects() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const { t } = useTranslation();

  const handleNavClick = (section) => () => scrollToSection(section);

  const projectsArray = [
    {
      id: 0,
      alt: "PLR",
      img: PLRLogo,
      type: "Website",
      projectImages: [PLR3, PLR, PLR2],
    },
    {
      id: 1,
      alt: "Gildan",
      img: GildanLogo,
      type: "DAM Portal",
      projectImages: [Gildan, Gildan2, Gildan3],
    },
    {
      id: 2,
      alt: "FawTrucks",
      img: FawTrucksLogo,
      type: "CRM",
      projectImages: [ELAM, ELAM2, ELAM3, ELAM4, ELAM5],
    },
    {
      id: 3,
      alt: "JPW",
      img: JPWLogo,
      type: "CRM",
      projectImages: [JPW2, JPW, JPW3],
    },
    {
      id: 4,
      alt: "Xcape",
      img: XcapeLogo,
      type: "Website",
      projectImages: [XCAPE, XCAPE2, XCAPE3],
    },
    {
      id: 5,
      alt: "UTL",
      img: UTLLogo,
      type: "Logistics System",
      projectImages: [UTL, UTL2, UTL3, UTL4, UTL5, UTL6, UTL7, UTL8, UTL9],
    },
  ];

  const openModal = (project) => {
    setIsModalOpen(true);
    setSelectedProject(project);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={selectedProject && selectedProject.alt}
        logo={selectedProject && selectedProject.img}
        images={selectedProject && selectedProject.projectImages}
      />
      <ScrollAnimation id="portfolio" className="projects maxWidth">
        <div className="titleContainer">
          <h3 className="subtitle">{t("workExamples")}</h3>
          <h2 className="title">{t("featuredProjects")}</h2>
        </div>
        <div className="grid">
          {projectsArray.map((project) => (
            <div className="projectBox" key={project.id}>
              <img
                className="projectLogo"
                src={project.img}
                alt={project.alt}
                loading="lazy" // Lazy loading images
                width="150" // Define image width for better layout performance
                height="150" // Define image height for better layout performance
                aria-label={`${project.alt} logo`} // Add aria-label for accessibility
              />
              <h3>{project.type}</h3>
              <button
                className="modalButton"
                type="button"
                onClick={() => openModal(project)}
                aria-label={`View details for ${project.alt}`} // Aria label for better screen reader support
              >
                {t("viewProject") + " >"}
              </button>
            </div>
          ))}
        </div>
        <button
          className="btn"
          onClick={handleNavClick("contact")}
          aria-label="Scroll to contact section"
        >
          {t("letsTalk")}
        </button>
      </ScrollAnimation>
    </>
  );
}
